// fxLayout="row" fxLayoutAlign="start center"
.fx-row-start-center {
	flex-direction: row;
	box-sizing: border-box;
	display: flex;
	place-content: center flex-start;
	align-items: center;
}

// fxLayout="row" fxLayoutAlign="space-between center"
.fx-row-space-between-center {
	flex-flow: row;
	box-sizing: border-box;
	display: flex;
	place-content: center space-between;
	align-items: center;
}

.fx-row-end {
	flex-direction: row;
	box-sizing: border-box;
	display: flex;
	place-content: stretch flex-end;
	align-items: stretch;
}

// fxLayout="row" fxLayoutAlign="space-evenly center"
.fx-row-space-evenly-center {
	flex-direction: row;
	box-sizing: border-box;
	display: flex;
	place-content: center space-evenly;
	align-items: center;
}

// fxFlex="70"
.fx-flex-100 {
	flex: 1 1 100%;
	max-width: 100%;
}