@mixin gea-table-theme {
  width: 100%;
  padding-left: 2%;
  padding-right: 2%;

  th.mat-header-cell {
      font-weight: bold;
      font-size: calc(12px + .3vw);
    }

  tr.mat-row {
    &:hover {
      cursor: pointer;
      background-color: #ccc;
    }
  }
  td.mat-cell {
    font-size: calc(14px + .4vw);
  }
}
